<template>
  <div
    class="bg-gray-50 h-full scrollbar scrollbar-thumb-gray-500 scrollbar-track-gray-200 scrollbar-thin content-wrap"
  >
    <NavBar
      ref="navbar"
      :toggled="sideBarToggled"
      @sideBarToggled="setSideBarToggled"
      :auth="auth"
      @auth="setAuth"
    />
    <!-- SideBar component is conditionally rendered only if auth is true -->
    <SideBar
      @sideBarToggled="setSideBarToggled"
      :toggled="sideBarToggled"
      :role="role"
      :auth="auth"
    />
    <!--  loading the associated component for a route and displaying it inside the <router-view> without full page refresh-->
    <div>
      <!-- capture the emitted event from the pages to open the feedbackModal -->
      <router-view
        @open-feedback-modal="handleFeedbackModal"
        @auth="setAuth"
        :role="role"
        :id="id"
      />
    </div>

    <spinner v-if="loading" />

    <Footer class="footer" />
  </div>
</template>

<script>
import api from "@/services/data";
import Spinner from "./components/Spinner.vue";
import NavBar from "./components/NavBar.vue";
import SideBar from "./components/SideBar.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    Spinner,
    NavBar,
    SideBar,
    Footer,
  },
  data() {
    return {
      loading: false,
      auth: false,
      role: "",
      id: "",
      sideBarToggled: false,
      isAdmin: false,
    };
  },
  methods: {
    handleFeedbackModal() {
      this.$refs.navbar.openModal(); // You need to add a ref to NavBar to access its methods
    },
    setAuth(status) {
      this.auth = status;
    },
    setSideBarToggled(status) {
      this.sideBarToggled = status;
    },

    async isLoggedIn() {
      //check if there's token in local storage
      let token = localStorage.getItem("token");
      //let resetToken = localStorage.getItem("resetToken");

      //if logged in change auth to true & get user profile, if not redirect to login page

      if (token) {
        let parsed = JSON.parse(atob(token.split(".")[1]));
        let role = parsed.user_role;
        this.role = role;
      } else {
        this.$router.replace({ name: "Login" });
      }
    },

    async getData(role) {
      //this.loading = true;
      console.log(role);

      const res = await api.getProfile();

      this.username = res.name;
      /*** TEMPORARY: set role based on user id, as it is not currently set in back end ***/
      if (res.id === 1) {
        this.role = "admin";
      }
      // this.role = res.role_id;
      this.id = res.id;

      this.auth = true;

      this.$store.state.id = this.id;
      this.$store.state.role = this.role;
    },

    logout() {
      // Clear token and reset auth and isAdmin
      localStorage.removeItem("token");
      this.auth = false;
      this.isAdmin = false;
      this.id = "";
    },
  },
  created() {
    if (localStorage.getItem("auth") === "true") {
      // Set the auth variable to true if it is set in local storage
      this.auth = true;
    }
    if (this.$route.meta.requiresAuth) {
      this.isLoggedIn().then(this.getData(this.role));
    }
  },
  watch: {
    $route() {
      this.sideBarToggled = false;
    },
    auth: async function () {
      if (this.auth === true) {
        const isLoggedIn = await this.isLoggedIn();
        if (isLoggedIn) {
          await this.getData(this.role);
        }
      }
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.content-wrap {
  flex: 1;
  /* This pushes the footer down */
}
</style>
