import { createWebHashHistory, createRouter } from "vue-router";
import store from "./store";

const Home = () => import(/* webpackChunkName: "home" */ "@/views/Home.vue");
const Login = () => import(/* webpackChunkName: "login" */ "@/views/Login.vue");
const PCP_fmd = () => import("@/views/PCP_fmd.vue");
const DiagnosticSupport = () => import("@/views/DiagnosticSupport.vue");
const RiskView = () => import("@/views/RiskView.vue");
const Vaccination = () => import("./components/RiskSharing/Vaccination.vue");
const VaccinationPurchases = () =>
  import("./components/RiskSharing/VaccinationPurchases.vue");
const MitigationMeasures = () =>
  import("./components/RiskSharing/MitigationMeasures.vue");
const PCP_fmd_Map = () => import("@/views/PCP_fmd_Map.vue");
const GetPreparedWall = () => import("@/views/GetPreparedWall.vue");
const FastReport = () => import("@/views/FastReport.vue");
const RMT = () => import("@/views/RMT.vue");
const RMTLanding = () => import("@/views/RMTLanding.vue");
const RMTResults = () => import("@/views/RMTResults.vue");
const RMTpdf = () => import("@/views/RMTpdf.vue");
const Vademos = () => import("@/views/Vademos.vue");
const Targets = () => import("@/views/Targets.vue");
const TrainingData = () => import("@/views/TrainingData.vue");
const ViewPastTrainingData = () => import("@/views/ViewPastTrainingData.vue");
const EmergencyToolbox = () => import("@/views/EmergencyToolbox.vue");

/***** All routes with "requiresAdmin" meta will only be visible to EUFMD internal account *****/
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresAuth: false }, // Allow access without authentication
  },
  {
    path: "/PCP_fmd",
    name: "PCP FMD",
    component: PCP_fmd,
    meta: { requiresAdmin: true },
  },

  {
    path: "/diagnostic-support",
    name: "DiagnosticSupport",
    component: DiagnosticSupport,
    meta: { requiresAdmin: true },
  },

  {
    path: "/RiskInformation",
    name: "RiskView",
    component: RiskView,
    meta: { requiresAdmin: true },
  },
  {
    path: "/fast-report",
    name: "Fast Report",
    component: FastReport,
    meta: { requiresAdmin: false }, // Accessible to everyone
  },
  {
    path: "/RMT",
    name: "RMT > RMT-FAST information",
    component: RMTLanding,
    meta: { requiresAuth: false },
  },
  {
    path: "/RMT/risk_scores",
    name: "RMT > Risk Scores",
    component: RMT,
    meta: { requiresAuth: false },
  },
  {
    path: "/RMT/results",
    name: "RMT > Results",
    component: RMTResults,
    meta: { requiresAuth: false },
  },
  {
    path: "/RMT/pdf",
    name: "RMT > Results > PDF",
    component: RMTpdf,
    meta: { requiresAuth: false },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/Vaccination",
    name: "Vaccination",
    component: Vaccination,
    meta: { requiresAdmin: true },
  },
  {
    path: "/VaccinationPurchases",
    name: "VaccinationPurchases",
    component: VaccinationPurchases,
    meta: { requiresAdmin: true },
  },
  {
    path: "/MitigationMeasures",
    name: "MitigationMeasures",
    component: MitigationMeasures,
    meta: { requiresAdmin: true },
  },
  {
    path: "/PCP-fmd-map",
    name: "PCP FMD Map",
    component: PCP_fmd_Map,
    meta: { requiresAdmin: false },
  },
  {
    path: "/get-prepared-wall",
    name: "GetPreparedWall",
    component: GetPreparedWall,
    meta: { requiresAdmin: false },
  },
  {
    path: "/emergency-toolbox",
    name: "EmergencyToolbox",
    component: EmergencyToolbox,
    meta: { requiresAdmin: false },
  },
  {
    path: "/Vademos",
    name: "Vademos",
    component: Vademos,
    meta: { requiresAdmin: false },
  },
  {
    path: "/Targets",
    name: "Targets",
    component: Targets,
    meta: { requiresAdmin: true },
  },
  {
    path: "/training-data",
    name: "TrainingData",
    component: TrainingData,
    meta: { requiresAdmin: true },
  },
  {
    path: "/view-past-training-impact",
    name: "ViewTrainingImpact",
    component: ViewPastTrainingData,
    meta: { requiresAdmin: false },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});
//redirect user depending on user's role
router.beforeEach((to, from, next) => {
  console.log("Navigating from", from.name, "to", to.name);

  //get token from local storage
  let token = localStorage.getItem("token");

  //if route is restricted to CM let them pass but redirect learner to their own page
  if (to.meta.requiresAuth) {
    ////handle no token in localStorage
    if (!token) {
      next({ path: "/login" });
    } else {
      let parsed = JSON.parse(atob(token.split(".")[1]));
      let id = parsed.user_id;
      let role = parsed.user_role;

      store.commit("changeRole", role);
      role === 2 && store.commit("changeUser", id);
      next();
    }
  }

  // Guard: redirect to login if the user is not admin
  else if (to.meta.requiresAdmin) {
    // if no token, user should log in
    if (!token) {
      next({ path: "/login" });
    } else {
      let parsed = JSON.parse(atob(token.split(".")[1]));
      let id = parsed.user_id;
      // if user id does not match admin
      if (id !== 1) {
        throw new Error("You do not have permission to view this resource.");
        // next({ path: "/login" });
      } else {
        next();
      }
    }
  }
  //if route is not protected let everyone in
  else {
    next();
  }
});

export default router;
