<template>
  <div class="h-80 bg-new text-green-100 font-marta">
    <div class="flex justify-center">
      <div class="py-10 px-1">
        <div class="flex justify-center">
          <img
            class="w-80 max-w-full"
            src="/static/assets/EU+LETTERS_negative.png"
            alt="EuFMD logo"
          />
        </div>

        <div class="mt-10">
          <div class="space-y-3 text-md font-semibold">
            <p>
              European Commission for the Control of Foot-and-Mouth Disease MOVE
              FAST strategy. <br />Foot-and-mouth snd Similar Transboundary
              animal diseases
            </p>
            <p>
              <a
                href="https://www.fao.org/eufmd"
                target="_blank"
                class="hover:text-white"
                >www.fao.org/eufmd</a
              >
            </p>
            <Visits />
            <p>© {{ currentYear }} | EuFMD</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Visits from "./Shared/Visits.vue";
export default {
  components: { Visits },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style></style>
