<template>
  <div class="visits-widget">
    <p>Total visits: {{ latestVisitId }}</p>
  </div>
</template>

<script>
import api from "@/services/data.js";

export default {
  name: "Visits",
  data() {
    return {
      latestVisitId: null, // Variable to store the latest visit ID
      todayDate: this.getTodayDate(),
    };
  },
  mounted() {
    console.log("Token on mount:", localStorage.getItem("token")); // Check token value

    this.recordVisit(); // Record a visit when the component is mounted
    this.fetchLatestVisitId(); // Fetch the latest visit ID
  },
  methods: {
    // Format today's date as YYYY-MM-DD
    getTodayDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    async fetchLatestVisitId() {
      try {
        console.log(
          "Token before fetching latest visit ID:",
          localStorage.getItem("token")
        ); // Check token value

        const data = await api.getVisits(); // Fetch data from the API
        this.latestVisitId = data.latestVisitId; // Set the latestVisitId from the API response
        console.log("Latest Visit ID is: ", this.latestVisitId); // Log for debugging
      } catch (error) {
        console.error("Error fetching latest visit ID:", error);
      }
    },
    async recordVisit() {
      try {
        console.log(
          "Token before recording visit:",
          localStorage.getItem("token")
        ); // Check token value

        await api.recordVisits(); // Call API to record visit
        console.log("Visit recorded.");
      } catch (error) {
        console.error("Error recording visit:", error);
      }
    },
  },
};
</script>

<style></style>
