<template>
    <div class="absolute inset-0 h-screen flex justify-center items-center">
      <div
        id="spinner"
        class="z-50 border-4 rounded-full w-10 h-10 animate-spin"
      ></div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Spinner",
  };
  </script>
  
  <style scoped>
  #spinner {
    border-top-color: #015039;
  }
  </style>
  