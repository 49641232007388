<template>
  <div
    class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
  >
    <div class="bg-white p-5 rounded shadow-lg w-11/12 max-w-lg">
      <span
        class="text-gray-500 text-2xl font-bold float-right cursor-pointer"
        @click="closeModal"
        >&times;</span
      >
      <div v-if="!confirmationVisible">
        <p class="text-xl font-bold pb-4">
          {{ `Did you find ${route} useful?` }}
        </p>
        <div class="mt-2">
          <p class="font-bold text-left">
            Rate this page from 1 to 10:<span class="text-red-600">*</span>
          </p>
          <div class="flex pb-3">
            <div
              v-for="number in 10"
              :key="number"
              @click="score = number"
              :class="[
                'w-10 h-10 m-1 font-bold flex items-center justify-center cursor-pointer rounded',
                score === number ? 'text-white bg-blue-500' : 'bg-opacity-75',
              ]"
              :style="{
                color: score === number ? 'white' : 'black',
                backgroundColor:
                  score === number
                    ? 'rgba(0, 123, 255, 0.8)'
                    : getGradientColor(number),
              }"
            >
              {{ number }}
            </div>
          </div>
        </div>
        <p class="text-left">
          <strong
            >Help us improve, please leave your comment, (optional):</strong
          >
        </p>
        <textarea
          v-model="comment"
          placeholder="Enter your comment"
          class="w-full mt-2 p-2 border rounded"
        ></textarea>
        <!-- Conditionally render country input based on user being logged in or not-->
        <p class="text-left">
          <strong v-if="!userLoggedIn">Enter your country:</strong
          ><span v-if="!userLoggedIn" class="text-red-600">*</span>
        </p>
        <input
          v-model="localCountry"
          type="text"
          placeholder="Type a country name"
          class="w-full mt-2 p-2 border rounded"
          v-if="!userLoggedIn"
        />
        <button
          @click="submitComment"
          class="font-bold mt-3 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
        >
          Submit
        </button>
      </div>
      <p v-if="confirmationVisible" class="mt-3 text-green-700 font-bold">
        Thank you for your feedback!
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeedbackModal",
  props: {
    route: {
      type: String,
      required: true,
    },
    userId: {
      type: Number,
      required: false,
    },
    closeModal: {
      type: Function,
      required: true,
    },
    country: {
      type: String,
      default: "",
    },
    userLoggedIn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      score: null, // This will store the selected score
      comment: "",
      confirmationVisible: false,

      localCountry: this.country,
    };
  },
  watch: {
    country(newValue) {
      this.localCountry = newValue; // Update localCountry when prop changes
    },
    localCountry(newValue) {
      this.$emit("update:country", newValue); // Emit updated value when it changes
      // Log the country when it changes
      console.log("COUNTRY UPDATED:", newValue);
    },
  },
  methods: {
    startAutoCloseTimer() {
      setTimeout(() => {
        this.confirmationVisible = false;
        this.closeModal();
      }, 2000); // 2 seconds
    },

    async submitComment() {
      if (this.score === null) {
        alert("Please select a score before submitting.");
        return;
      }

      const feedbackData = {
        page: this.route,
        score: this.score,
        comment: this.comment.trim() === "" ? null : this.comment,
        country: this.localCountry,
        user_id: this.userId ? this.userId : null, // Setting user_id based on login state
      };

      console.log("Submitting feedback:", feedbackData);

      try {
        const response = await this.addFeedback(feedbackData);
        console.log("Server response:", response);

        if (response && response.message === "Feedback entry created") {
          this.confirmationVisible = true;
          // Emit the feedback submission event to the parent
          this.submitFeedback();
          this.startAutoCloseTimer();
        } else {
          alert("An error occurred. Please try again.");
        }
      } catch (error) {
        console.error("Failed to submit feedback:", error);
        alert("Failed to submit feedback. Please try again later.");
      }
    },
    submitFeedback() {
      // Emit an event when feedback is submitted
      this.$emit("feedback-submitted");
    },
    async addFeedback(feedbackData) {
      try {
        const response = await fetch("api/feedback/add", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": localStorage.getItem("token"),
          },
          body: JSON.stringify(feedbackData),
        });

        const textResponse = await response.text();
        let jsonResponse;

        try {
          jsonResponse = JSON.parse(textResponse);
        } catch (e) {
          throw new Error(`Unexpected response: ${textResponse}`);
        }

        return jsonResponse;
      } catch (error) {
        console.log("API call error:", error);
        throw error;
      }
    },
    getGradientColor(number) {
      const totalSteps = 10; // Total steps from 1 to 10
      const step = (number - 1) / (totalSteps - 1);

      // Define color points
      const red = [255, 0, 0]; // Red
      const orange = [255, 165, 0]; // Orange
      const yellow = [255, 255, 0]; // Yellow
      const green = [0, 255, 0]; // Green

      // Interpolation function
      const interpolate = (startColor, endColor, t) => {
        return startColor.map((start, i) =>
          Math.round(start + t * (endColor[i] - start))
        );
      };

      if (step < 0.25) {
        // Red to Orange
        const t = step / 0.25;
        const color = interpolate(red, orange, t);
        return `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.5)`;
      } else if (step < 0.75) {
        // Orange to Yellow
        const t = (step - 0.25) / 0.5;
        const color = interpolate(orange, yellow, t);
        return `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.5)`;
      } else {
        // Yellow to Green
        const t = (step - 0.75) / 0.25;
        const color = interpolate(yellow, green, t);
        return `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.5)`;
      }
    },
  },
};
</script>

<style scoped>
/* Ensure to use Tailwind CSS utilities */
.bg-gradient-to-r {
  background: linear-gradient(to right, #f56565, #fbbf24, #07bf54);
}
</style>
