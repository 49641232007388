<template>
  <div
    id="sidebar"
    :class="[toggled ? `w-64` : `w-0`]"
    class="fixed h-full z-50 top-0 left-0 bg-greens text-green-100 overflow-auto"
  >
    <div>
      <div class="px-2 py-3 mt-2">
        <p class="top-1 right-3 absolute cursor-pointer">
          <font-awesome-icon icon="times" @click="closeSideBar" />
        </p>
      </div>
      <div
        class="flex flex-col justify-center p-5 mt-20 font-martaBold"
        :class="[toggled ? `` : `hidden`]"
      >
        <hr class="my-3 hr-text gradient" data-content="" />

        <div v-if="auth">
          <router-link
            class="flex gap-3 items-center px-1 py-2 bg-transparent rounded-lg md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
            to="/diagnostic-support"
          >
            <font-awesome-icon icon="file-csv" class="text-xl" />
            <span
              class="tooltip rounded shadow-lg p-1 bg-black text-white -mt-8 text-center"
            >
              Diagnostic Support</span
            >
            Diagnostic Support</router-link
          >
        </div>

        <div v-if="auth">
          <router-link
            class="flex gap-3 items-center px-1 py-2 bg-transparent rounded-lg md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
            to="/PCP_fmd"
          >
            <font-awesome-icon icon="file-csv" class="text-xl" />
            <span
              class="tooltip rounded shadow-lg p-1 bg-black text-white mt-8 text-center"
              >PCP FMD</span
            >
            PCP FMD</router-link
          >
        </div>

        <div v-if="auth">
          <router-link
            class="flex gap-3 items-center px-1 py-2 bg-transparent rounded-lg md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
            to="/Targets"
          >
            <font-awesome-icon icon="file-csv" class="text-xl" />
            <span
              class="tooltip rounded shadow-lg p-1 bg-black text-white mt-8 text-center"
              >Targets</span
            >
            Targets</router-link
          >
        </div>

        <div v-if="auth">
          <router-link
            class="flex gap-3 items-start px-1 py-2 bg-transparent rounded-lg md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
            to="/training-data"
          >
            <font-awesome-icon icon="file-csv" class="text-xl" />
            <span
              class="tooltip rounded shadow-lg p-1 bg-black text-white -mt-8 text-left"
            >
              Training Impact</span
            >
            Training Data</router-link
          >
        </div>

        <hr class="my-3 hr-text gradient" data-content="" />
        <h1 class="border-white border-2 p-2 rounded">Tools and Resources</h1>
        <div>
          <router-link
            class="flex gap-3 items-center px-1 py-2 bg-transparent rounded-lg md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
            to="/get-prepared-wall"
          >
            <font-awesome-icon icon="tools" class="text-xl" />
            <span
              class="tooltip rounded shadow-lg p-1 bg-black text-white -mt-8 text-center"
            >
              Get Prepared Wall
            </span>
            Get Prepared Wall</router-link
          >
        </div>
        <div>
          <router-link
            class="flex gap-3 items-center px-1 py-2 bg-transparent rounded-lg md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
            to="/emergency-toolbox"
          >
            <font-awesome-icon icon="tools" class="text-xl" />
            <span
              class="tooltip rounded shadow-lg p-1 bg-black text-white -mt-8 text-center"
            >
              Emergency Toolbox
            </span>
            Emergency Toolbox</router-link
          >
        </div>
        <div>
          <a
            href="https://eufmd-tom.com"
            target="_blank"
            rel="noopener noreferrer"
            class="flex gap-3 items-center px-1 py-2 bg-transparent rounded-lg md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
          >
            <font-awesome-icon icon="tools" class="text-xl mr-0" />
            <span
              class="tooltip rounded shadow-lg p-5 bg-black text-white -mt-8 text-center"
            >
              TOM
            </span>
            TOM
          </a>
        </div>
        <div>
          <router-link
            class="flex gap-3 items-center px-1 py-2 bg-transparent rounded-lg md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
            to="/view-past-training-impact"
          >
            <font-awesome-icon icon="tools" class="text-xl" />
            <span
              class="tooltip rounded shadow-lg bg-black text-white text-center"
            >
              Training HP_Training_impact
            </span>
            Training Impact
          </router-link>
        </div>

        <div>
          <router-link
            class="flex gap-3 items-center px-1 py-2 bg-transparent rounded-lg md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
            to="/fast-report"
          >
            <font-awesome-icon icon="tools" class="text-xl" />
            <span
              class="tooltip rounded shadow-lg p-1 bg-black text-white -mt-8 text-center"
            >
              Fast Report
            </span>
            Fast Report</router-link
          >
        </div>

        <div v-if="auth">
          <router-link
            class="flex gap-3 items-center px-1 py-2 bg-transparent rounded-lg md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
            to="/RMT"
          >
            <font-awesome-icon icon="tools" class="text-xl" />
            <span
              class="tooltip rounded shadow-lg p-1 bg-black text-white -mt-8 text-center"
            >
              RMT
            </span>
            RMT</router-link
          >
        </div>

        <div>
          <router-link
            class="flex gap-3 items-center px-1 py-2 bg-transparent rounded-lg md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
            to="/Vademos"
          >
            <font-awesome-icon icon="tools" class="text-xl" />
            <span
              class="tooltip rounded shadow-lg p-1 bg-black text-white -mt-8 text-center"
            >
              VADEMOS
            </span>
            VADEMOS</router-link
          >
        </div>
        <div>
          <router-link
            class="flex gap-3 items-center px-1 py-2 bg-transparent rounded-lg md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
            to="/PCP-fmd-map"
          >
            <font-awesome-icon icon="tools" class="text-xl" />
            <span
              class="tooltip rounded shadow-lg p-1 bg-black text-white mt-8 text-center"
              >PCP-FMD Map</span
            >
            PCP-FMD Map</router-link
          >
        </div>
        <hr class="my-3 hr-text gradient" data-content="" />
        <div v-if="auth">
          <router-link
            class="flex gap-3 items-center px-1 py-2 bg-transparent rounded-lg md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
            to="/RiskInformation"
          >
            <font-awesome-icon icon="tools" class="text-xl" />
            <span
              class="tooltip rounded shadow-lg p-1 bg-black text-white mt-8"
            >
              RISP
            </span>
            <div class="flex flex-col items-start">
              <span>RISP</span>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  props: {
    auth: {
      type: Boolean,
      required: true,
    },
    toggled: {
      type: Boolean,
      required: true,
    },
    /*  role: {
      type: String,
      required: true,
    }, */
  },
  // check if the user is admin
  /* computed: {
    isAdmin() {
      return this.role === "admin";
    },
  }, */
  methods: {
    closeSideBar() {
      // notify parent component (App) of sidebar closing
      this.$emit("sideBarToggled", false);
    },
    openSideBar() {
      this.$emit("sideBarToggled", true);
    },
  },

  created() {
    //add event listener so sidebar closes when user clicks outside of it

    window.addEventListener("click", (e) => {
      if (!this.$el.contains(e.target)) {
        this.$emit("sideBarToggled", false);
      }
    });
  },
};
</script>

<style scoped>
#sidebar {
  transition: width 1s ease-in-out;
}

.hr-text {
  border: 0;
  font-size: 14px;
  height: 1.5em;
  line-height: 1em;
  position: relative;
  text-align: left;
}

.hr-text::before {
  content: "";
  background: linear-gradient(
    to left,
    transparent,
    rgb(220 252 231),
    transparent
  );
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}

.hr-text::after {
  color: white;
  content: attr(data-content);
  line-height: 1em;
  padding: 0 7px;
  position: relative;
}
</style>
