import { createStore } from "vuex";

export default createStore({
  state() {
    return {
      id: "",
      role: "",
      feedbackGiven: {
        emergencytoolbox: false,
        getpreparedwall: false,
      },
    };
  },
  mutations: {
    changeUser(state, id) {
      state.id = id;
    },
    setFeedbackGiven(state, route) {
      const normalizedRoute = route.replace(/\s+/g, "").toLowerCase(); // Normalize route
      if (normalizedRoute in state.feedbackGiven) {
        state.feedbackGiven[normalizedRoute] = true; // Mark feedback as given
      } else {
        console.error(`Feedback for route "${route}" not found in state.`);
      }
    },
  },
});
