export default {
  login(email, password) {
    return fetch("api/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    })
      .then((response) => {
        console.log("RESPONSE IN DATA LOGIN:", response); // Log the response
        return response.json();
      })
      .catch((error) => console.log(error));
  },

  async getProfile() {
    try {
      const response = await fetch("api/auth/profile", {
        method: "GET",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      });
      // Log the raw response object
      console.log("RESPONSE DATA RESPONSE:", response);
      // Check if the response is okay
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      // Parse the JSON response
      const data = await response.json();
      // Log the parsed data
      console.log("Parsed response data:", data);
      return data; // Return the parsed data
    } catch (error) {
      console.log(error);
    }
  },

  ////  /* ---- Procurement (diagnostic support)  ---- */

  //  not necessarry when filtering fetched data in the backend. If fetching data in the frontend like here, I need it
  async getProcurements() {
    try {
      const response = await fetch("/api/diagnostic-support", {
        method: "GET",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      });
      return await response.json();
    } catch (error) {
      console.log(error);
    }
  },

  async addProcurement({
    stock_id,
    product,
    serotype,
    quantity,
    delivery_date,
    country_id,
    LOA_DP, // Adjusted to match the server-side parameter name
    supplier,
    description,
    PO,
    expiry_date,
    notes,
    report_usage, // Adjusted to match the server-side parameter name
  }) {
    // Create the request payload
    const requestData = {
      stock_id,
      product,
      serotype,
      quantity,
      delivery_date,
      country_id,
      ["LOA/DP"]: LOA_DP, // Adjusted to handle special characters and matches server-side
      supplier,
      description,
      PO,
      expiry_date,
      report_usage, // Adjusted to match the server-side parameter name
      notes,
    };

    // Log the request payload
    console.log("Request Data:", JSON.stringify(requestData, null, 2));

    try {
      const response = await fetch("/api/diagnostic-support/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(requestData),
      });

      // Check for non-200 response status
      if (!response.ok) {
        const errorData = await response.json();
        console.error(
          "Server Error:",
          errorData.message || "Unknown error occurred"
        );
        throw new Error(errorData.message || "Unknown error occurred");
      }

      return await response.json();
    } catch (error) {
      console.error("Error adding procurement:", error);
      throw error; // Optionally, rethrow or handle error further up the call chain
    }
  },

  async deleteProcurement(id) {
    try {
      const response = await fetch(`/api/diagnostic-support/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error deleting diagnostic support entry:", error);
      throw error;
    }
  },

  /// /* -----Countries--- */

  async getCountries() {
    try {
      const response = await fetch("/api/diagnostic-support/countries", {
        method: "GET",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      });
      return await response.json();
    } catch (error) {
      console.log(error);
    }
  },

  /// /* -----Stock--- */

  async getStocks() {
    try {
      const response = await fetch("api/stock", {
        method: "GET",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      });
      return await response.json();
    } catch (error) {
      console.log(error);
    }
  },

  async addStock({
    LOA_DP,
    supplier,
    PO,
    product,
    serotype,
    code,
    quantity,
    expiry_date,
    notes,
  }) {
    try {
      const response = await fetch("api/stock/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          LOA_DP,
          supplier,
          PO,
          product,
          serotype,
          code,
          quantity,
          expiry_date,
          notes,
        }),
      });
      return await response.json();
    } catch (error) {
      console.log(error);
    }
  },

  async updateStockEntry(id, changes) {
    try {
      const response = await fetch(`api/stock/update/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(changes), // `changes` received here
      });

      if (!response.ok) {
        throw new Error("Failed to update stock entry");
      }

      return await response.json();
    } catch (error) {
      console.log(error);
    }
  },
  async deleteStockEntry(id) {
    try {
      const response = await fetch(`/api/stock/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error deleting stock entry:", error);
      throw error;
    }
  },

  /// /* -----LOAs--- */

  async getLOAs() {
    try {
      const response = await fetch("api/LOA", {
        method: "GET",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      });
      return await response.json();
    } catch (error) {
      console.log(error);
    }
  },

  async addLOA({
    group,
    responsible,
    supplier,
    start_date,
    end_date,
    description,
    PO,
  }) {
    try {
      const response = await fetch("api/LOA/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          group,
          responsible,
          supplier,
          start_date,
          end_date,
          description,
          PO,
        }),
      });
      return await response.json();
    } catch (error) {
      console.log(error);
    }
  },

  async updateLOA(id, changes) {
    try {
      const response = await fetch(`api/LOA/update/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify(changes), // `changes` received here
      });

      if (!response.ok) {
        throw new Error("Failed to update LOA");
      }

      return await response.json();
    } catch (error) {
      console.log(error);
    }
  },
  async deleteLOA(id) {
    try {
      const response = await fetch(`/api/LOA/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error deleting LOA entry:", error);
      throw error;
    }
  },

  /// /* -----PCP--- */

  async getPCP() {
    try {
      const response = await fetch("api/pcp", {
        method: "GET",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      });
      return await response.json();
    } catch (error) {
      console.log(error);
    }
  },

  async addPCPData({ Country, Year, PCP_Stage, Last_RMM_held, psoSupport }) {
    try {
      const response = await fetch("api/pcp/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          Country,
          Year,
          PCP_Stage,
          Last_RMM_held,
          psoSupport,
        }),
      });
      return await response.json();
    } catch (error) {
      console.log(error);
    }
  },

  async updatePCP({ country, year, stage }) {
    try {
      const response = await fetch("api/pcp/update", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          country,
          year,
          stage,
        }),
      });
      return await response.json();
    } catch (error) {
      console.log(error);
    }
  },

  /// /* -----Training--- */

  async addNMCourse({ shortname, fullname, summary }) {
    try {
      const response = await fetch("api/training/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          shortname,
          fullname,
          summary,
        }),
      });
      return await response.json();
    } catch (error) {
      console.log(error);
    }
  },

  /// /* -----Download report --- */

  async downloadReport(params = {}) {
    const queryParams = new URLSearchParams(params).toString();

    try {
      const response = await fetch(`api/pcp/download/?${queryParams}`, {
        method: "GET",
        headers: {
          "x-access-token": localStorage.getItem("token"),
        },
      });
      return await response.json();
    } catch (error) {
      console.log(error);
    }
  },

  /// /* -----Feedback--- NOT BEING USED AT THE MOMENT. TO BE CONFIRMED */

  /* async addFeedback({ page, score, comment, country, user_id }) {
    console.log("SENDING REQUEST WITH:", {
      page,
      score,
      comment,
      country,
      user_id,
    }); 

    try {
      const response = await fetch("api/feedback/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          page,
          score,
          comment,
          country,
          user_id: user_id || null, // Use the user_id passed in or null for guests
        }),
      });
      return await response.json()
      const data = await response.json();
      console.log("Server response:", data);
      return data;
    } catch (error) {
      console.log(error);
    }
  },
 */
  /// /* -----Visits--- */

  async getVisits() {
    try {
      const response = await fetch("api/visits", {
        method: "GET",
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return await response.json(); // Parse JSON response
    } catch (error) {
      console.error("Error fetching visit data:", error);
      throw error; // Ensure the error is propagated
    }
  },

  async recordVisits() {
    try {
      const response = await fetch("api/visits/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return await response.json(); // Ensure this is returning the correct format
    } catch (error) {
      console.error("Error recording visit:", error);
      throw error;
    }
  },

  /// /* -----Risp--- */

  async addRISP(allCampaigns) {
    try {
      const response = await fetch("api/risp/add", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-access-token": localStorage.getItem("token"),
        },
        body: JSON.stringify({
          allCampaigns,
        }),
      });
      return await response.json();
    } catch (error) {
      console.log(error);
    }
  },
};
