<template>
  <div :class="!toggled ? 'w-screen' : 'flex-grow'">
    <div
      class="text-white bg-new flex justify-between items-center py-3 px-6 z-50"
    >
      <font-awesome-icon
        name="open"
        icon="bars"
        @click.stop="openSideBar"
        class="cursor-pointer text-lg"
        v-if="!toggled"
      />

      <div
        :class="[
          'container flex justify-between items-center transition-all duration-3000',
          { 'ml-64 ease-in-out': toggled },
        ]"
      >
        <div>
          <img
            id="logo"
            class="-ml-3 md:w-60 max-w-full h-auto"
            src="/static/assets/EuFMD_White.png"
            alt="European Comission for the Control of Foot-and-Mouth-Disease"
          />
        </div>

        <!-- if not logged in it shows a log in route-->
        <router-link
          v-if="!auth"
          class="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
          to="/login"
        >
          Log In
        </router-link>

        <router-link
          class="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-lg md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
          to="/"
          @click="logOut"
          name="open"
          v-if="auth"
        >
          <div class="flex flex-row items-center" >
            <p class="">
              {{ username }}
            </p>
            <font-awesome-icon
              icon="sign-out-alt"
              class="ml-2 cursor-pointer text-lg"
            />
          </div>
          
        </router-link>
      </div>
    </div>

    <div
      v-show="$route.name !== 'Login'"
      class="w-screen bg-white py-3 text-green-primary font-marta"
    >
      <div class="container">
        <p class="font-light">
          <span class="mr-1">
            <router-link
              class="font-black capitalize text-2xl m-3 font-martaBold hover:text-green-secondary hover:underline"
              to="/"
              >Home</router-link
            >
          </span>

          <span
            v-show="route !== 'Home'"
            class="font-black text-xl font-martaBold"
            >>> {{ route }}</span
          >
        </p>
        <!--   Displays a Feedback button that triggers the modal when clicked if the current route is not Home -->
        <div
          v-show="route !== 'Home'"
          class="group flex justify-end cursor-pointer hover:animate-bounce"
        >
          <p class="font-semibold pr-3 place-self-center" @click="openModal()">
            Feedback
          </p>
          <div class="relative group">
            <font-awesome-icon
              icon="thumbs-up"
              class="text-2xl cursor-pointer m-1"
              @click="openModal()"
            />
          </div>
          <div class="relative group">
            <font-awesome-icon
              icon="thumbs-down"
              class="text-2xl cursor-pointer m-1"
              @click="openModal()"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <FeedbackModal
      v-if="showModal"
      :thumbType="selectedThumb"
      :route="route"
      :country="country"
      :userId="userId"
      :closeModal="closeModal"
      :userLoggedIn="userLoggedIn"
      @feedback-submitted="handleFeedbackSubmitted"
      class="z-50"
    ></FeedbackModal>
  </div>
</template>

<script>
import api from "@/services/data";
import { mapState } from "vuex";
import FeedbackModal from "@/components/Shared/FeedbackModal.vue";

export default {
  components: { FeedbackModal },
  name: "NavBar",
  props: ["toggled", "auth"],
  data() {
    return {
      username: "",
      route: "",
      showModal: false,
      selectedThumb: "",
      country: "",
      userId: "",
      isDataFetched: false,
    };
  },
  computed: {
    // Using the auth prop to determine login status
    userLoggedIn() {
      return this.auth;
    },
    ...mapState(["feedbackGiven"]),
    logoClass() {
      return this.toggled ? "ml-64" : "";
    },
  },

  methods: {
    logOut() {
      // notify parent component (App) of logout
      this.$emit("auth", false);

      // remove token from local storage
      localStorage.clear();
    },
    async openModal(fetchData = false) {
      if (fetchData) {
        await this.getData();
      }
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    handleFeedbackSubmitted() {
      // Call the Vuex mutation to set feedback as given
      this.$store.commit("setFeedbackGiven", this.route.toLowerCase());
      console.log("Feedback given state:", this.$store.state.feedbackGiven);
      this.closeModal();
    },
    async getData() {
      if (this.isDataFetched) return;
      const res = await api.getProfile();
      if (res) {
        this.username = res.name;
        // get also the country
        this.country = res.country;
        this.userId = res.id;
        this.isDataFetched = true;
      } else {
        console.log("No data received for user profile");
      }
    },
    openSideBar() {
      this.$emit("sideBarToggled", true);
    },
    processRoute(str) {
      return (
        str.charAt(0).toUpperCase() +
        this.$route.name.slice(1).replace(/([a-z])([A-Z])/g, "$1 $2")
      );
    },
    checkFeedback() {
      const feedbackRequiredRoutes = ["EmergencyToolbox", "GetPreparedWall"];
      if (
        feedbackRequiredRoutes.includes(this.route) &&
        !this.feedbackGiven[this.route.toLowerCase()]
      ) {
        this.openModal(true);
      }
    },
  },
  created() {
    if (this.auth && !this.isDataFetched) {
      this.getData(); // Fetch data if user is logged in
    }
  },
  watch: {
    $route() {
      this.route = this.processRoute(this.$route.name);
      // Check if the current route is emergencyToolbox
      this.checkFeedback();
    },
    auth(newAuth) {
      if (newAuth) {
        this.getData(); // Call getData only when the user is logging in
      } else {
        // Clear user-related data when logging out
        this.username = "";
        this.country = "";
        this.userId = "";
        this.isDataFetched = false;
      }
    },
  },
};
</script>

<style scoped>
#logo {
  transition: margin-left 1s ease-in-out;
}
</style>
